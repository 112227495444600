class tecologyAccordion {
	constructor( accordionAccordion ) {
		this.accordion = accordionAccordion;
		this.accordionItemAccordion = this.accordion.querySelectorAll(
			'.tecology-accordion-item'
		);
		this.accordionTitles = this.accordion.querySelectorAll(
			'.tecology-accordion-title'
		);
		this.accordionContents = this.accordion.querySelectorAll(
			'.tecology-accordion-content'
		);
		this.accordionContentInnerAccordions = this.accordion.querySelectorAll(
			'.tecology-accordion-content-inner-accordion'
		);
		this.searchElements = this.accordion.querySelectorAll(
			'.wp-block-search'
		);

		// saving accordion content heights.
		this.accordionContentsHeights = Array.from(
			this.accordionContents
		).map( ( contentElem ) => {
			let computedStyle = getComputedStyle( contentElem );
			let elementHeight = contentElem.clientHeight;

			elementHeight -=
				parseFloat( computedStyle.paddingTop ) +
				parseFloat( computedStyle.paddingBottom );

			return elementHeight;
		} );

		this.accordionContentsPadding = Array.from(
			this.accordionContents
		).map( ( contentElem ) =>
			getComputedStyle( contentElem ).getPropertyValue( 'padding' )
		);

		// Datasets
		this.defaultFirstItemOpen = this.accordion.dataset.open_first;
		this.openOneAtATime = this.accordion.dataset.open_one;

		//initialization
		this.initialize();
	}
	initialize() {
		this.attachEventHandler();
		this.showInitialAccordion();
		this.removeInitializeClass();
		if ( this.searchElements.length > 0 ) {
			this.attachSearchEvents();
		}
	}
	searchAccordion( value ) {
		this.accordionItemAccordion.forEach( ( item, index ) => {
			const searchedAccordion = item.innerText
				.toLowerCase()
				.includes( value.toLowerCase().trim() );
			if ( ! searchedAccordion ) {
				item.classList.add( 'tecology-accordion-hide' );
			} else {
				item.classList.remove( 'tecology-accordion-hide' );
			}
		} );
	}
	attachSearchEvents() {
		this.searchElements?.forEach( ( element ) => {
			element.addEventListener( 'submit', ( e ) => {
				e.preventDefault();
			} );
			const searchInput = element.querySelector(
				'.wp-block-search__input'
			);
			const searchButton = element.querySelector(
				'.wp-block-search__button'
			);
			searchInput.required = false;
			searchButton.addEventListener( 'click', () => {
				this.searchAccordion( searchInput.value );
			} );
			searchInput.addEventListener( 'keydown', ( e ) => {
				if ( e.keyCode !== 13 ) {
					return;
				}
				this.searchAccordion( e.target.value );
			} );
		} );
	}
	removeInitializeClass() {
		this.accordionContents.forEach( ( content ) => {
			if (
				content.classList.contains(
					'tecology-accordion-content-initialized'
				)
			) {
				content.classList.remove(
					'tecology-accordion-content-initialized'
				);
			}
		} );
	}
	showInitialAccordion() {
		let isInitialAccordionOpen =
			this.defaultFirstItemOpen === 'true' ? true : false;
		if (
			isInitialAccordionOpen &&
			this.accordionItemAccordion.length > 0
		) {
			this.accordionItemAccordion[ 0 ].classList.add(
				'tecology-active-accordion'
			);

			this.accordionContents[ 0 ].style.height =
				this.accordionContentsHeights[ 0 ] + 'px';

			this.accordionContents[ 0 ].style.padding = this.accordionContentsPadding[ 0 ];
		}
	}
	setActiveAccordion( idx ) {
		let openOneAtATime = this.openOneAtATime === 'true' ? true : false;

		// Accordion
		if ( ! openOneAtATime && this.accordionItemAccordion.length > 0 ) {
			this.accordionItemAccordion[ idx ].classList.toggle(
				'tecology-active-accordion'
			);
		} else {
			this.accordionItemAccordion.forEach(
				( accordion, accordionIndex ) => {
					if (
						idx === accordionIndex &&
						this.accordionItemAccordion.length > 0
					) {
						accordion.classList.toggle( 'tecology-active-accordion' );

						this.accordionContents[ accordionIndex ].style.height =
							this.accordionContentsHeights[ accordionIndex ] +
							'px';

						this.accordionContents[
							accordionIndex
						].style.padding = this.accordionContentsPadding[
							accordionIndex
						];
					} else {
						accordion.classList.remove( 'tecology-active-accordion' );
						this.accordionContents[
							accordionIndex
						].style.height = 0;

						this.accordionContents[
							accordionIndex
						].style.paddingTop = 0;

						this.accordionContents[
							accordionIndex
						].style.paddingBottom = 0;
					}
				}
			);
		}
		// Accordion
		if (
			this.accordionItemAccordion[ idx ].classList.contains(
				'tecology-active-accordion'
			)
		) {
			this.accordionContents[ idx ].style.height =
				this.accordionContentsHeights[ idx ] + 'px';

			this.accordionContents[
				idx
			].style.padding = this.accordionContentsPadding[ idx ];
		} else {
			this.accordionContents[ idx ].style.height = 0;

			this.accordionContents[ idx ].style.paddingTop = 0;

			this.accordionContents[ idx ].style.paddingBottom = 0;
		}
	}
	attachEventHandler() {
		this.accordionTitles.forEach( ( title, idx ) => {
			this.accordionContents[ idx ].style.height = 0;
			this.accordionContents[ idx ].style.paddingTop = 0;

			this.accordionContents[ idx ].style.paddingBottom = 0;

			setTimeout( () => {
				this.accordionContents[ idx ].classList.add(
					'tecology-accordion-content-animation'
				);
			}, 250 );
			title.addEventListener( 'click', ( event ) => {
				event.stopPropagation();
				this.setActiveAccordion( idx );
			} );
		} );
	}
}
window.addEventListener( 'load', () => {
	const accordionAccordions = document.querySelectorAll( '.tecology-accordion' );
	accordionAccordions.forEach(
		( accordionAccordion ) => new tecologyAccordion( accordionAccordion )
	);
} );
